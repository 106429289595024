.content-list41-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.content-list41-max-width {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.content-list41-content1 {
  align-self: stretch;
  align-items: flex-start;
}
.content-list41-ul1 {
  align-items: flex-start;
}
.content-list41-li1 {
  text-align: left;
  align-items: flex-start;
}
.content-list41-heading7 {
  align-self: center;
}
.content-list41-content2 {
  align-self: stretch;
  align-items: flex-start;
}
.content-list41-ul2 {
  align-items: flex-start;
}
.content-list41-li2 {
  text-align: left;
  align-items: flex-start;
}
.content-list41-content3 {
  align-self: stretch;
  align-items: flex-start;
}
.content-list41-ul3 {
  align-items: flex-start;
}
.content-list41-li3 {
  text-align: left;
  align-items: flex-start;
}
.content-list41-text1 {
  display: inline-block;
}
.content-list41-text2 {
  display: inline-block;
}
.content-list41-text3 {
  display: inline-block;
}
.content-list41-text4 {
  display: inline-block;
}
