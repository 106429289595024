.features175-layout349 {
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.features175-max-width {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  align-items: center;
}
.features175-image-container {
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
}
.features175-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.features175-section-title {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.features175-text10 {
  font-size: 26px;
  font-style: italic;
  font-weight: 600;
}
.features175-content2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.features175-text11 {
  fill: var(--dl-color-theme-primary2);
  color: var(--dl-color-theme-primary2);
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
}
.features175-text12 {
  font-size: 22px;
}
.features175-text13 {
  display: inline-block;
}
.features175-text14 {
  display: inline-block;
}
.features175-text15 {
  display: inline-block;
}
.features175root-class-name {
  display: none;
}
.features175root-class-name1 {
  display: none;
}
@media(max-width: 991px) {
  .features175-max-width {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
  }
}
