.privacy-policy-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text100 {
  display: inline-block;
}
.privacy-policy-text101 {
  display: inline-block;
}
.privacy-policy-text102 {
  display: inline-block;
}
.privacy-policy-text103 {
  display: inline-block;
}
.privacy-policy-text104 {
  display: inline-block;
}
.privacy-policy-text105 {
  display: inline-block;
}
.privacy-policy-text106 {
  display: inline-block;
}
.privacy-policy-text107 {
  display: inline-block;
}
.privacy-policy-text108 {
  display: inline-block;
}
.privacy-policy-text109 {
  display: inline-block;
}
.privacy-policy-text112 {
  display: inline-block;
}
.privacy-policy-text134 {
  font-weight: 700;
}
.privacy-policy-text148 {
  font-weight: 700;
}
.privacy-policy-text177 {
  font-weight: 700;
}
.privacy-policy-text208 {
  font-weight: 700;
}
.privacy-policy-text229 {
  font-weight: 700;
}
.privacy-policy-text237 {
  font-weight: 700;
}
.privacy-policy-text259 {
  font-weight: 700;
}
.privacy-policy-text290 {
  font-weight: 700;
}
.privacy-policy-text301 {
  font-weight: 700;
}
.privacy-policy-text306 {
  font-weight: 700;
}
.privacy-policy-text311 {
  font-weight: 700;
}
.privacy-policy-text316 {
  font-weight: 700;
}
.privacy-policy-text321 {
  font-weight: 700;
}
.privacy-policy-text329 {
  font-weight: 700;
}
.privacy-policy-text334 {
  font-weight: 700;
}
.privacy-policy-text339 {
  font-weight: 700;
}
.privacy-policy-text344 {
  font-weight: 700;
}
.privacy-policy-text349 {
  font-weight: 700;
}
.privacy-policy-text374 {
  font-weight: 700;
}
.privacy-policy-text385 {
  font-weight: 700;
}
.privacy-policy-text390 {
  font-weight: 700;
}
.privacy-policy-text395 {
  font-weight: 700;
}
.privacy-policy-text400 {
  font-weight: 700;
}
.privacy-policy-text404 {
  font-weight: 700;
}
.privacy-policy-text405 {
  font-weight: 700;
}
.privacy-policy-text409 {
  display: inline-block;
}
.privacy-policy-text410 {
  display: inline-block;
}
.privacy-policy-text411 {
  display: inline-block;
}
.privacy-policy-text414 {
  display: inline-block;
}
.privacy-policy-text415 {
  display: inline-block;
}
.privacy-policy-text420 {
  display: inline-block;
}
.privacy-policy-text427 {
  display: inline-block;
}
@media(max-width: 479px) {
  .privacy-policy-text120 {
    font-size: 22px;
  }
  .privacy-policy-text123 {
    font-size: 22px;
  }
  .privacy-policy-text126 {
    font-size: 22px;
  }
  .privacy-policy-text129 {
    font-size: 22px;
  }
  .privacy-policy-text132 {
    font-size: 22px;
  }
  .privacy-policy-text134 {
    font-weight: 700;
  }
  .privacy-policy-text140 {
    font-size: 22px;
  }
  .privacy-policy-text142 {
    font-size: 22px;
  }
  .privacy-policy-text144 {
    font-size: 22px;
  }
  .privacy-policy-text146 {
    font-size: 22px;
  }
  .privacy-policy-text148 {
    font-weight: 700;
  }
  .privacy-policy-text151 {
    font-size: 22px;
  }
  .privacy-policy-text152 {
    font-size: 22px;
  }
  .privacy-policy-text154 {
    font-size: 22px;
  }
  .privacy-policy-text156 {
    font-size: 22px;
  }
  .privacy-policy-text158 {
    font-size: 22px;
  }
  .privacy-policy-text160 {
    font-size: 22px;
  }
  .privacy-policy-text162 {
    font-size: 22px;
  }
  .privacy-policy-text164 {
    font-size: 22px;
  }
  .privacy-policy-text166 {
    font-size: 22px;
  }
  .privacy-policy-text167 {
    font-size: 22px;
  }
  .privacy-policy-text169 {
    font-size: 22px;
  }
  .privacy-policy-text170 {
    font-size: 22px;
  }
  .privacy-policy-text172 {
    font-size: 22px;
  }
  .privacy-policy-text173 {
    font-size: 22px;
  }
  .privacy-policy-text175 {
    font-size: 22px;
  }
  .privacy-policy-text177 {
    font-weight: 700;
  }
  .privacy-policy-text183 {
    font-size: 22px;
  }
  .privacy-policy-text185 {
    font-size: 22px;
  }
  .privacy-policy-text187 {
    font-size: 22px;
  }
  .privacy-policy-text189 {
    font-size: 22px;
  }
  .privacy-policy-text191 {
    font-size: 22px;
  }
  .privacy-policy-text193 {
    font-size: 22px;
  }
  .privacy-policy-text195 {
    font-size: 22px;
  }
  .privacy-policy-text197 {
    font-size: 22px;
  }
  .privacy-policy-text199 {
    font-size: 22px;
  }
  .privacy-policy-text201 {
    font-size: 22px;
  }
  .privacy-policy-text206 {
    font-size: 22px;
  }
  .privacy-policy-text208 {
    font-weight: 700;
  }
  .privacy-policy-text211 {
    font-size: 22px;
  }
  .privacy-policy-text214 {
    font-size: 22px;
  }
  .privacy-policy-text217 {
    font-size: 22px;
  }
  .privacy-policy-text220 {
    font-size: 22px;
  }
  .privacy-policy-text221 {
    font-size: 22px;
  }
  .privacy-policy-text223 {
    font-size: 22px;
  }
  .privacy-policy-text225 {
    font-size: 22px;
  }
  .privacy-policy-text227 {
    font-size: 22px;
  }
  .privacy-policy-text229 {
    font-weight: 700;
  }
  .privacy-policy-text232 {
    font-size: 22px;
  }
  .privacy-policy-text235 {
    font-size: 22px;
  }
  .privacy-policy-text237 {
    font-weight: 700;
  }
  .privacy-policy-text240 {
    font-size: 22px;
  }
  .privacy-policy-text246 {
    font-size: 22px;
  }
  .privacy-policy-text248 {
    font-size: 22px;
  }
  .privacy-policy-text250 {
    font-size: 22px;
  }
  .privacy-policy-text252 {
    font-size: 22px;
  }
  .privacy-policy-text254 {
    font-size: 22px;
  }
  .privacy-policy-text257 {
    font-size: 22px;
  }
  .privacy-policy-text259 {
    font-weight: 700;
  }
  .privacy-policy-text262 {
    font-size: 22px;
  }
  .privacy-policy-text263 {
    font-size: 22px;
  }
  .privacy-policy-text265 {
    font-size: 22px;
  }
  .privacy-policy-text274 {
    font-size: 22px;
  }
  .privacy-policy-text276 {
    font-size: 22px;
  }
  .privacy-policy-text279 {
    font-size: 22px;
  }
  .privacy-policy-text282 {
    font-size: 22px;
  }
  .privacy-policy-text285 {
    font-size: 22px;
  }
  .privacy-policy-text288 {
    font-size: 22px;
  }
  .privacy-policy-text290 {
    font-weight: 700;
  }
  .privacy-policy-text293 {
    font-size: 22px;
  }
  .privacy-policy-text296 {
    font-size: 22px;
  }
  .privacy-policy-text299 {
    font-size: 22px;
  }
  .privacy-policy-text301 {
    font-weight: 700;
  }
  .privacy-policy-text304 {
    font-size: 22px;
  }
  .privacy-policy-text306 {
    font-weight: 700;
  }
  .privacy-policy-text309 {
    font-size: 22px;
  }
  .privacy-policy-text311 {
    font-weight: 700;
  }
  .privacy-policy-text314 {
    font-size: 22px;
  }
  .privacy-policy-text316 {
    font-weight: 700;
  }
  .privacy-policy-text319 {
    font-size: 22px;
  }
  .privacy-policy-text321 {
    font-weight: 700;
  }
  .privacy-policy-text324 {
    font-size: 22px;
  }
  .privacy-policy-text327 {
    font-size: 22px;
  }
  .privacy-policy-text329 {
    font-weight: 700;
  }
  .privacy-policy-text332 {
    font-size: 22px;
  }
  .privacy-policy-text334 {
    font-weight: 700;
  }
  .privacy-policy-text337 {
    font-size: 22px;
  }
  .privacy-policy-text338 {
    font-weight: 700;
  }
  .privacy-policy-text339 {
    font-weight: 700;
  }
  .privacy-policy-text342 {
    font-size: 22px;
  }
  .privacy-policy-text344 {
    font-weight: 700;
  }
  .privacy-policy-text347 {
    font-size: 22px;
  }
  .privacy-policy-text349 {
    font-weight: 700;
  }
  .privacy-policy-text352 {
    font-size: 22px;
  }
  .privacy-policy-text358 {
    font-size: 22px;
  }
  .privacy-policy-text360 {
    font-size: 22px;
  }
  .privacy-policy-text362 {
    font-size: 22px;
  }
  .privacy-policy-text364 {
    font-size: 22px;
  }
  .privacy-policy-text366 {
    font-size: 22px;
  }
  .privacy-policy-text368 {
    font-size: 22px;
  }
  .privacy-policy-text370 {
    font-size: 22px;
  }
  .privacy-policy-text372 {
    font-size: 22px;
  }
  .privacy-policy-text374 {
    font-weight: 700;
  }
  .privacy-policy-text377 {
    font-size: 22px;
  }
  .privacy-policy-text383 {
    font-size: 22px;
  }
  .privacy-policy-text384 {
    font-weight: 700;
  }
  .privacy-policy-text385 {
    font-weight: 700;
  }
  .privacy-policy-text388 {
    font-size: 22px;
  }
  .privacy-policy-text390 {
    font-weight: 700;
  }
  .privacy-policy-text393 {
    font-size: 22px;
  }
  .privacy-policy-text395 {
    font-weight: 700;
  }
  .privacy-policy-text398 {
    font-size: 22px;
  }
  .privacy-policy-text400 {
    font-weight: 700;
  }
  .privacy-policy-text403 {
    font-size: 22px;
  }
  .privacy-policy-text405 {
    font-weight: 700;
  }
}
