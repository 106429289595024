.team1-max-width {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.team1-section-title {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-shrink: 0;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 0px;
  flex-direction: column;
  justify-content: flex-start;
}
.team1-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: center;
  align-items: center;
  border-color: #ffffff;
  border-width: 0px;
  flex-direction: column;
}
.team1-text10 {
  text-align: center;
}
.team1-text11 {
  text-align: center;
}
.team1-content2 {
  gap: var(--dl-space-space-sixunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-content3 {
  gap: 64px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.team1-row {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
}
.team1-container1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.team1-card1 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 500px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.team1-placeholder-image1 {
  width: 80px;
  height: 80px;
}
.team1-content4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-title1 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-text12 {
  font-style: normal;
  font-weight: 600;
}
.team1-text13 {
  text-align: center;
}
.team1-social-icons1 {
  gap: var(--dl-space-space-unit);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
}
.team1-card2 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 500px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.team1-placeholder-image2 {
  width: 80px;
  height: 80px;
}
.team1-content5 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-title2 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-text14 {
  font-style: normal;
  font-weight: 600;
}
.team1-text15 {
  text-align: center;
}
.team1-social-icons2 {
  gap: var(--dl-space-space-unit);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
}
.team1-container2 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.team1-card3 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 500px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.team1-placeholder-image3 {
  width: 80px;
  height: 80px;
}
.team1-content6 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-title3 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-text16 {
  font-style: normal;
  font-weight: 600;
}
.team1-text17 {
  text-align: center;
}
.team1-social-icons3 {
  gap: var(--dl-space-space-unit);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
}
.team1-card4 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 500px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.team1-placeholder-image4 {
  width: 80px;
  height: 80px;
}
.team1-content7 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-title4 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team1-text18 {
  font-style: normal;
  font-weight: 600;
}
.team1-text19 {
  text-align: center;
}
.team1-social-icons4 {
  gap: var(--dl-space-space-unit);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
}
.team1-text20 {
  display: inline-block;
}
.team1-text21 {
  display: inline-block;
}
.team1-text22 {
  display: inline-block;
}
.team1-text23 {
  display: inline-block;
}
.team1-text26 {
  display: inline-block;
}
.team1-text27 {
  display: inline-block;
}
.team1-text28 {
  display: inline-block;
}
.team1-text29 {
  display: inline-block;
}
.team1-text30 {
  display: inline-block;
}
.team1-text31 {
  display: inline-block;
}
.team1root-class-name {
  background-color: #16224a;
}
.team1root-class-name1 {
  background-color: #16224a;
}
@media(max-width: 991px) {
  .team1-content2 {
    gap: var(--dl-space-space-fourunits);
  }
}
@media(max-width: 767px) {
  .team1-row {
    align-items: center;
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .team1-text10 {
    text-align: left;
  }
  .team1-text11 {
    text-align: left;
  }
  .team1-container1 {
    flex-direction: column;
  }
  .team1-container2 {
    flex-direction: column;
  }
  .team1-text23 {
    font-size: 22px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 400;
  }
  .team1root-class-name1 {
    align-self: flex-start;
  }
}
