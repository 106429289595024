.logos11-container1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.logos11-max-width {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.logos11-text1 {
  text-align: center;
}
.logos11-container2 {
  animation-name: none;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.logos11-logo1 {
  object-fit: contain;
}
.logos11-logo2 {
  object-fit: contain;
}
.logos11-logo3 {
  object-fit: contain;
}
.logos11-logo4 {
  object-fit: contain;
}
.logos11-logo5 {
  object-fit: contain;
}
.logos11-logo6 {
  object-fit: contain;
}
.logos11-text2 {
  display: inline-block;
}
.logos11root-class-name {
  background-color: var(--dl-color-theme-secondary1);
}
.logos11root-class-name1 {
  background-color: var(--dl-color-theme-secondary1);
}
@media(max-width: 767px) {
  .logos11-container1 {
    gap: var(--dl-space-space-twounits);
  }
  .logos11-max-width {
    gap: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .logos11-text1 {
    text-align: left;
  }
}
