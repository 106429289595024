.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
}
.home-text100 {
  display: inline-block;
}
.home-text101 {
  display: inline-block;
}
.home-text102 {
  display: inline-block;
}
.home-text103 {
  display: inline-block;
}
.home-text104 {
  display: inline-block;
}
.home-text105 {
  display: inline-block;
}
.home-text106 {
  display: inline-block;
}
.home-text107 {
  display: inline-block;
}
.home-text108 {
  display: inline-block;
}
.home-text109 {
  display: inline-block;
}
.home-text112 {
  display: inline-block;
}
.home-text113 {
  display: inline-block;
}
.home-text114 {
  display: inline-block;
}
.home-text115 {
  display: inline-block;
}
.home-text116 {
  display: inline-block;
}
.home-text117 {
  display: inline-block;
}
.home-text118 {
  color: rgb(22, 34, 74);
  display: inline-block;
  font-size: 22px;
  font-weight: 600;
}
.home-text119 {
  color: rgb(22, 34, 74);
  display: inline-block;
  font-size: 40px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 800;
}
.home-text120 {
  color: rgb(255, 255, 255);
  display: inline-block;
  font-size: 40px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 800;
}
.home-text121 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-size: 30px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 700;
}
.home-text122 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-size: 30px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 700;
}
.home-text123 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-size: 30px;
  font-family: "League Spartan";
  font-weight: 700;
}
.home-text124 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-size: 30px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 700;
}
.home-text125 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-family: "League Spartan";
}
.home-text126 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-size: 16px;
  font-family: "League Spartan";
}
.home-text127 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-size: 16px;
  font-family: "League Spartan";
}
.home-text128 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 400;
}
.home-text129 {
  display: inline-block;
}
.home-text130 {
  color: rgb(22, 34, 74);
  display: inline-block;
}
.home-text131 {
  color: rgb(22, 34, 74);
  display: inline-block;
  font-size: 40px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 800;
}
.home-text132 {
  display: inline-block;
}
.home-text133 {
  display: inline-block;
}
.home-text134 {
  display: inline-block;
}
.home-text135 {
  display: inline-block;
}
.home-text136 {
  display: inline-block;
}
.home-text137 {
  display: inline-block;
}
.home-text138 {
  display: inline-block;
}
.home-text139 {
  display: inline-block;
}
.home-text140 {
  display: inline-block;
}
.home-text141 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-size: 40px;
}
.home-text142 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-size: 30px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 600;
}
.home-text143 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-size: 22px;
}
.home-text144 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-size: 40px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 800;
}
.home-text145 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-size: 30px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 600;
}
.home-text146 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.home-text147 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
}
.home-text148 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-size: 30px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 600;
}
.home-text149 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-size: 22px;
}
.home-text150 {
  display: inline-block;
}
.home-text151 {
  display: inline-block;
}
.home-text152 {
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 700;
}
.home-text154 {
  display: inline-block;
}
.home-text155 {
  color: rgb(22, 34, 74);
  display: inline-block;
  font-size: 40px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 800;
}
.home-text156 {
  color: rgb(22, 34, 74);
  display: inline-block;
  font-size: 40px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 800;
}
.home-text157 {
  display: inline-block;
}
.home-text158 {
  display: inline-block;
}
.home-text161 {
  display: inline-block;
}
.home-text162 {
  display: inline-block;
}
.home-text163 {
  display: inline-block;
}
.home-text164 {
  display: inline-block;
}
.home-text165 {
  display: inline-block;
}
.home-text166 {
  display: inline-block;
}
.home-text167 {
  display: inline-block;
}
.home-text168 {
  display: inline-block;
}
.home-text169 {
  display: inline-block;
}
.home-text170 {
  display: inline-block;
}
.home-text171 {
  display: inline-block;
}
.home-text172 {
  display: inline-block;
}
.home-text173 {
  display: inline-block;
}
.home-text174 {
  display: inline-block;
}
.home-text175 {
  display: inline-block;
}
.home-text176 {
  display: inline-block;
}
.home-text177 {
  display: inline-block;
}
.home-text178 {
  color: rgb(22, 34, 74);
  display: inline-block;
}
.home-text179 {
  color: rgb(22, 34, 74);
  display: inline-block;
  font-size: 40px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 800;
}
.home-text180 {
  display: inline-block;
}
.home-text181 {
  display: inline-block;
}
.home-text182 {
  display: inline-block;
}
.home-text183 {
  display: inline-block;
}
.home-text184 {
  display: inline-block;
}
.home-text185 {
  display: inline-block;
}
.home-text186 {
  display: inline-block;
}
.home-text187 {
  display: inline-block;
}
.home-text188 {
  display: inline-block;
  font-family: "League Spartan";
}
.home-text189 {
  display: inline-block;
}
.home-text190 {
  display: inline-block;
}
.home-text191 {
  display: inline-block;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 400;
}
.home-text192 {
  display: inline-block;
}
.home-text193 {
  display: inline-block;
}
.home-text194 {
  display: inline-block;
}
.home-text195 {
  display: inline-block;
}
.home-text196 {
  display: inline-block;
}
.home-text197 {
  display: inline-block;
}
.home-text198 {
  display: inline-block;
}
.home-text199 {
  display: inline-block;
}
.home-text200 {
  display: inline-block;
}
.home-text201 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.home-text202 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.home-text203 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.home-text204 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.home-text205 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.home-text206 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-size: 40px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 800;
}
.home-text207 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.home-text208 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.home-text209 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.home-text210 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.home-text211 {
  color: #16224a;
  display: inline-block;
}
.home-text214 {
  display: inline-block;
}
.home-text215 {
  display: inline-block;
}
.home-text220 {
  display: inline-block;
}
.home-text227 {
  display: inline-block;
}
@media(max-width: 1600px) {
  .home-text131 {
    color: #16224a;
    font-size: 40px;
  }
}
@media(max-width: 479px) {
  .home-text118 {
    font-size: 22px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 600;
  }
  .home-text119 {
    font-size: 40px;
  }
  .home-text125 {
    font-size: 16px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 400;
  }
  .home-text126 {
    font-size: 16px;
  }
  .home-text127 {
    font-size: 16px;
  }
  .home-text128 {
    font-size: 16px;
  }
  .home-text141 {
    font-size: 30px;
  }
  .home-text142 {
    font-size: 22px;
  }
  .home-text143 {
    font-size: 16px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 400;
  }
  .home-text144 {
    font-size: 30px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 800;
  }
  .home-text145 {
    font-size: 22px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 600;
  }
  .home-text146 {
    font-size: 16px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 400;
  }
  .home-text147 {
    font-size: 30px;
  }
  .home-text148 {
    font-size: 22px;
  }
  .home-text149 {
    font-size: 16px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 400;
  }
  .home-text178 {
    font-size: 22px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 400;
  }
  .home-text205 {
    font-size: 22px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 400;
  }
}
