.features182-layout349 {
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.features182-max-width {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  align-items: center;
}
.features182-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.features182-section-title {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.features182-text10 {
  font-size: 26px;
  font-style: italic;
  font-weight: 600;
}
.features182-content2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.features182-text11 {
  color: var(--dl-color-theme-primary2);
  font-size: 30px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 800;
}
.features182-text12 {
  font-size: 22px;
}
.features182-image-container {
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
}
.features182-text13 {
  display: inline-block;
  font-size: 40px;
}
.features182-text14 {
  display: inline-block;
  font-size: 22px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 400;
}
.features182-text22 {
  display: inline-block;
  font-size: 30px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 600;
}
.features182root-class-name {
  background-color: #16224a;
}
.features182root-class-name1 {
  background-color: #16224a;
}
.features182root-class-name2 {
  background-color: #16224a;
}
.features182root-class-name3 {
  background-color: #16224a;
}
@media(max-width: 991px) {
  .features182-max-width {
    gap: var(--dl-space-space-twounits);
    flex-direction: column-reverse;
  }
}
@media(max-width: 479px) {
  .features182-text13 {
    font-size: 30px;
  }
  .features182-text14 {
    font-size: 16px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 400;
  }
  .features182-text22 {
    font-size: 22px;
  }
}
