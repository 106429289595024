.features231-layout349 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.features231-max-width {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.features231-container1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
}
.features231-text10 {
  color: var(--dl-color-theme-primary2);
  font-size: 45px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 700;
}
.features231-container3 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-accent1);
}
.features231-icon10 {
  height: auto;
}
.features231-text11 {
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 700;
}
.features231-text12 {
  font-size: 22px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 400;
}
.features231-container4 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary2);
}
.features231-text13 {
  color: #ffffff;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 700;
}
.features231-text14 {
  color: #ffffff;
  font-size: 22px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 400;
}
.features231-container5 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-accent1);
}
.features231-text15 {
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 700;
}
.features231-text16 {
  font-size: 22px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 400;
}
.features231-container6 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary2);
}
.features231-text17 {
  color: #ffffff;
  font-family: "League Spartan";
}
.features231-text18 {
  color: #ffffff;
  font-size: 22px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 400;
}
.features231-text19 {
  display: inline-block;
}
.features231-text20 {
  display: inline-block;
}
.features231-text21 {
  display: inline-block;
}
.features231-text22 {
  display: inline-block;
}
.features231-text23 {
  display: inline-block;
}
.features231-text24 {
  display: inline-block;
}
.features231-text25 {
  display: inline-block;
}
.features231-text26 {
  display: inline-block;
}
.features231-text27 {
  display: inline-block;
}
.features231root-class-name {
  display: none;
}
.features231root-class-name1 {
  display: none;
}
@media(max-width: 991px) {
  .features231-max-width {
    flex-direction: column;
  }
  .features231-container1 {
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .features231-container1 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .features231-container3 {
    width: 100%;
  }
  .features231-container4 {
    width: 100%;
  }
  .features231-container5 {
    width: 100%;
  }
  .features231-container6 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .features231-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .features231-text10 {
    text-align: center;
  }
}
