.pricing13-pricing23 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.pricing13-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.pricing13-section-title {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.pricing13-content {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 800px;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing13-text10 {
  color: #16224a;
  font-size: 40px;
  font-family: "League Spartan";
}
.pricing13-text11 {
  color: #16224a;
  font-size: 22px;
  text-align: center;
  font-family: "League Spartan";
}
.pricing13-tabs {
  display: flex;
  align-items: flex-start;
}
.pricing13-button1 {
  gap: var(--dl-space-space-halfunit);
  width: 120px;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-theme-primary1);
  border-style: solid;
  border-width: 1px;
  justify-content: center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.pricing13-button2 {
  gap: var(--dl-space-space-halfunit);
  color: var(--dl-color-theme-neutral-light);
  width: 120px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--dl-color-theme-primary1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.pricing13-container1 {
  gap: 32px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  animation-name: fadeIn;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.pricing13-column1 {
  flex: 1;
  width: 100%;
  height: 647px;
  display: flex;
  align-self: stretch;
  align-items: center;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  background-color: var(--dl-color-theme-accent1);
}
.pricing13-icon10 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  align-self: flex-end;
}
.pricing13-price1 {
  gap: var(--dl-space-space-twounits);
  height: 542px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing13-price2 {
  gap: var(--dl-space-space-halfunit);
  height: 167px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing13-text14 {
  color: rgb(22, 34, 74);
  font-size: 22px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 600;
}
.pricing13-text15 {
  color: rgb(22, 34, 74);
  font-size: 50px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 600;
}
.pricing13-text16 {
  color: rgb(22, 34, 74);
  font-size: 22px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 600;
}
.pricing13-list1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.pricing13-list-item10 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing13-icon12 {
  fill: #16224a;
}
.pricing13-text17 {
  color: #16224a;
  font-family: "League Spartan";
}
.pricing13-list-item11 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing13-icon14 {
  fill: #16224a;
}
.pricing13-text18 {
  color: #16224a;
  font-family: "League Spartan";
}
.pricing13-list-item12 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing13-icon16 {
  fill: #16224a;
}
.pricing13-text19 {
  color: rgb(22, 34, 74);
  font-family: "League Spartan";
}
.pricing13-list-item13 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing13-icon18 {
  fill: #16224a;
}
.pricing13-text20 {
  color: rgb(22, 34, 74);
  font-family: "League Spartan";
}
.pricing13-list-item14 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing13-text21 {
  color: rgb(22, 34, 74);
  font-family: "League Spartan";
}
.pricing13-action21 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  width: auto;
  height: auto;
  display: flex;
  border-color: #075ce2;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-imageradius);
  flex-direction: row;
  text-decoration: none;
  background-color: #075ce2;
}
.pricing13-text22 {
  color: var(--dl-color-theme-secondary1);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.pricing13-column2 {
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  background-color: #16224a;
}
.pricing13-price3 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing13-icon20 {
  fill: var(--dl-color-theme-secondary1);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  align-self: flex-end;
}
.pricing13-price4 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing13-text23 {
  color: var(--dl-color-theme-secondary1);
  font-size: 22px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 600;
}
.pricing13-text24 {
  color: var(--dl-color-theme-secondary1);
  font-size: 50px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 600;
}
.pricing13-text25 {
  color: var(--dl-color-theme-secondary1);
  font-size: 22px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 600;
}
.pricing13-list2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.pricing13-list-item15 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing13-icon22 {
  fill: var(--dl-color-theme-secondary1);
}
.pricing13-text26 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-family: "League Spartan";
}
.pricing13-list-item16 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing13-icon24 {
  fill: var(--dl-color-theme-secondary1);
}
.pricing13-text27 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-family: "League Spartan";
}
.pricing13-list-item17 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing13-icon26 {
  fill: var(--dl-color-theme-secondary1);
}
.pricing13-text28 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-family: "League Spartan";
}
.pricing13-list-item18 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing13-icon28 {
  fill: var(--dl-color-theme-secondary1);
}
.pricing13-text29 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-family: "League Spartan";
}
.pricing13-list-item19 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing13-text30 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-family: "League Spartan";
}
.pricing13-action22 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  width: auto;
  height: auto;
  display: flex;
  border-color: var(--dl-color-theme-secondary1);
  border-width: 0px;
  border-radius: var(--dl-radius-radius-imageradius);
  flex-direction: row;
  text-decoration: none;
  background-color: #075ce2;
}
.pricing13-text31 {
  color: var(--dl-color-theme-secondary1);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.pricing13-container2 {
  gap: 32px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  animation-name: fadeIn;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.pricing13-column3 {
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  background-color: var(--dl-color-theme-accent1);
}
.pricing13-icon30 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  align-self: flex-end;
}
.pricing13-price5 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing13-price6 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing13-text32 {
  font-style: normal;
  font-weight: 600;
}
.pricing13-text33 {
  font-size: 48px;
}
.pricing13-list3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.pricing13-list-item20 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing13-list-item21 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing13-list-item22 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing13-button3 {
  width: 100%;
}
.pricing13-column4 {
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  background-color: var(--dl-color-theme-accent2);
}
.pricing13-price7 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing13-icon38 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  align-self: flex-end;
}
.pricing13-price8 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing13-text39 {
  font-style: normal;
  font-weight: 600;
}
.pricing13-text40 {
  font-size: 48px;
}
.pricing13-list4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.pricing13-list-item23 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing13-list-item24 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing13-list-item25 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing13-list-item26 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing13-button4 {
  width: 100%;
}
.pricing13-text47 {
  display: inline-block;
}
.pricing13-text48 {
  display: inline-block;
}
.pricing13-text49 {
  display: inline-block;
}
.pricing13-text50 {
  display: inline-block;
}
.pricing13-text51 {
  display: inline-block;
}
.pricing13-text52 {
  display: inline-block;
}
.pricing13-text53 {
  display: inline-block;
}
.pricing13-text54 {
  display: inline-block;
}
.pricing13-text55 {
  display: inline-block;
}
.pricing13-text56 {
  display: inline-block;
}
.pricing13-text57 {
  display: inline-block;
}
.pricing13-text58 {
  display: inline-block;
}
.pricing13-text59 {
  display: inline-block;
}
.pricing13-text60 {
  display: inline-block;
}
.pricing13-text61 {
  display: inline-block;
}
.pricing13-text62 {
  display: inline-block;
}
.pricing13-text63 {
  display: inline-block;
}
.pricing13-text64 {
  display: inline-block;
}
.pricing13-text65 {
  display: inline-block;
}
.pricing13-text66 {
  display: inline-block;
}
.pricing13-text67 {
  display: inline-block;
}
.pricing13-text68 {
  display: inline-block;
}
.pricing13-text69 {
  display: inline-block;
}
.pricing13-text70 {
  display: inline-block;
}
.pricing13-text71 {
  display: inline-block;
}
.pricing13-text72 {
  display: inline-block;
}
.pricing13-text73 {
  display: inline-block;
}
.pricing13-text74 {
  display: inline-block;
}
.pricing13-text75 {
  display: inline-block;
}
.pricing13-text76 {
  display: inline-block;
}
.pricing13-text77 {
  display: inline-block;
}
.pricing13-text78 {
  display: inline-block;
}
.pricing13-text79 {
  display: inline-block;
}
.pricing13-text80 {
  display: inline-block;
}
.pricing13root-class-name {
  background-color: var(--dl-color-theme-secondary1);
}
.pricing13root-class-name1 {
  background-color: var(--dl-color-theme-secondary1);
}
@media(max-width: 991px) {
  .pricing13-container1 {
    flex-direction: column;
  }
  .pricing13-container2 {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .pricing13-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .pricing13-text11 {
    text-align: left;
  }
  .pricing13-price1 {
    height: 553px;
  }
  .pricing13-button3 {
    width: 100%;
  }
  .pricing13-button4 {
    width: 100%;
  }
  .pricing13-text80 {
    font-size: 22px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 400;
  }
}
