.homecn-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
}
.homecn-text100 {
  display: inline-block;
}
.homecn-text101 {
  display: inline-block;
}
.homecn-text102 {
  display: inline-block;
}
.homecn-text103 {
  display: inline-block;
}
.homecn-text104 {
  display: inline-block;
}
.homecn-text105 {
  display: inline-block;
}
.homecn-text106 {
  display: inline-block;
}
.homecn-text107 {
  display: inline-block;
}
.homecn-text108 {
  display: inline-block;
}
.homecn-text109 {
  display: inline-block;
}
.homecn-text110 {
  display: inline-block;
}
.homecn-text111 {
  display: inline-block;
}
.homecn-text112 {
  display: inline-block;
}
.homecn-text113 {
  display: inline-block;
}
.homecn-text114 {
  display: inline-block;
}
.homecn-text115 {
  display: inline-block;
}
.homecn-text116 {
  display: inline-block;
}
.homecn-text117 {
  color: rgb(22, 34, 74);
  display: inline-block;
  font-size: 22px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 600;
}
.homecn-text118 {
  color: rgb(22, 34, 74);
  display: inline-block;
  font-size: 40px;
}
.homecn-text119 {
  color: rgb(255, 255, 255);
  display: inline-block;
}
.homecn-text120 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-style: normal;
  font-weight: 800;
}
.homecn-text121 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 800;
}
.homecn-text122 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-style: normal;
  font-weight: 800;
}
.homecn-text123 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-style: normal;
  font-weight: 800;
}
.homecn-text124 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.homecn-text125 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.homecn-text126 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.homecn-text127 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.homecn-text128 {
  color: rgb(255, 255, 255);
  display: inline-block;
}
.homecn-text129 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-style: normal;
  font-weight: 800;
}
.homecn-text130 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 800;
}
.homecn-text131 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-style: normal;
  font-weight: 800;
}
.homecn-text132 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-style: normal;
  font-weight: 800;
}
.homecn-text133 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.homecn-text134 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.homecn-text135 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.homecn-text136 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.homecn-text137 {
  display: inline-block;
}
.homecn-text138 {
  color: rgb(22, 34, 74);
  display: inline-block;
  font-size: 22px;
}
.homecn-text139 {
  color: rgb(22, 34, 74);
  display: inline-block;
  font-size: 40px;
}
.homecn-text140 {
  display: inline-block;
}
.homecn-text141 {
  display: inline-block;
}
.homecn-text142 {
  display: inline-block;
}
.homecn-text143 {
  display: inline-block;
}
.homecn-text144 {
  display: inline-block;
}
.homecn-text145 {
  display: inline-block;
}
.homecn-text146 {
  display: inline-block;
}
.homecn-text147 {
  display: inline-block;
}
.homecn-text148 {
  display: inline-block;
}
.homecn-text149 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-size: 40px;
}
.homecn-text150 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
}
.homecn-text151 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-size: 22px;
}
.homecn-text152 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-size: 40px;
}
.homecn-text153 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
}
.homecn-text154 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.homecn-text155 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-size: 40px;
}
.homecn-text156 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
}
.homecn-text157 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
}
.homecn-text158 {
  display: inline-block;
}
.homecn-text159 {
  display: inline-block;
}
.homecn-text160 {
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 700;
}
.homecn-text162 {
  display: inline-block;
}
.homecn-text163 {
  color: rgb(22, 34, 74);
  display: inline-block;
  font-size: 40px;
}
.homecn-text164 {
  display: inline-block;
  font-size: 40px;
}
.homecn-text165 {
  display: inline-block;
}
.homecn-text166 {
  display: inline-block;
}
.homecn-text169 {
  display: inline-block;
}
.homecn-text170 {
  display: inline-block;
}
.homecn-text171 {
  display: inline-block;
}
.homecn-text172 {
  display: inline-block;
}
.homecn-text173 {
  color: rgb(22, 34, 74);
  display: inline-block;
  font-size: 40px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 800;
}
.homecn-text174 {
  display: inline-block;
}
.homecn-text175 {
  display: inline-block;
}
.homecn-text178 {
  display: inline-block;
}
.homecn-text179 {
  display: inline-block;
}
.homecn-text180 {
  display: inline-block;
}
.homecn-text181 {
  display: inline-block;
}
.homecn-text182 {
  display: inline-block;
}
.homecn-text183 {
  display: inline-block;
}
.homecn-text184 {
  display: inline-block;
}
.homecn-text185 {
  display: inline-block;
}
.homecn-text186 {
  display: inline-block;
}
.homecn-text187 {
  display: inline-block;
}
.homecn-text190 {
  display: inline-block;
}
.homecn-text191 {
  display: inline-block;
}
.homecn-text192 {
  display: inline-block;
}
.homecn-text195 {
  display: inline-block;
}
.homecn-text196 {
  display: inline-block;
}
.homecn-text197 {
  display: inline-block;
}
.homecn-text198 {
  display: inline-block;
}
.homecn-text199 {
  color: rgb(22, 34, 74);
  display: inline-block;
}
.homecn-text200 {
  color: rgb(22, 34, 74);
  display: inline-block;
}
.homecn-text201 {
  display: inline-block;
}
.homecn-text202 {
  display: inline-block;
}
.homecn-text203 {
  display: inline-block;
}
.homecn-text204 {
  display: inline-block;
}
.homecn-text205 {
  display: inline-block;
}
.homecn-text206 {
  display: inline-block;
}
.homecn-text207 {
  display: inline-block;
}
.homecn-text208 {
  display: inline-block;
}
.homecn-text209 {
  display: inline-block;
  font-family: "League Spartan";
}
.homecn-text210 {
  display: inline-block;
}
.homecn-text211 {
  display: inline-block;
}
.homecn-text212 {
  display: inline-block;
  font-family: "League Spartan";
}
.homecn-text213 {
  display: inline-block;
}
.homecn-text216 {
  display: inline-block;
}
.homecn-text217 {
  display: inline-block;
}
.homecn-text218 {
  display: inline-block;
}
.homecn-text219 {
  display: inline-block;
}
.homecn-text220 {
  display: inline-block;
}
.homecn-text221 {
  display: inline-block;
}
.homecn-text222 {
  display: inline-block;
}
.homecn-text223 {
  display: inline-block;
}
.homecn-text224 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.homecn-text225 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.homecn-text226 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.homecn-text227 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.homecn-text228 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-size: 22px;
}
.homecn-text229 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-size: 40px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 600;
}
.homecn-text230 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.homecn-text231 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.homecn-text232 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.homecn-text233 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
.homecn-text234 {
  color: #16224a;
  display: inline-block;
}
.homecn-text237 {
  display: inline-block;
}
.homecn-text238 {
  display: inline-block;
}
.homecn-text243 {
  display: inline-block;
}
.homecn-text250 {
  display: inline-block;
}
@media(max-width: 1600px) {
  .homecn-text139 {
    color: #16224a;
    font-size: 40px;
  }
  .homecn-text164 {
    color: rgb(22, 34, 74);
    font-size: 40px;
  }
}
@media(max-width: 479px) {
  .homecn-text117 {
    font-size: 22px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 600;
  }
  .homecn-text118 {
    font-size: 40px;
  }
  .homecn-text124 {
    font-size: 16px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 400;
  }
  .homecn-text125 {
    font-size: 16px;
  }
  .homecn-text126 {
    font-size: 16px;
  }
  .homecn-text127 {
    font-size: 16px;
  }
  .homecn-text133 {
    font-size: 16px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 400;
  }
  .homecn-text134 {
    font-size: 16px;
  }
  .homecn-text135 {
    font-size: 16px;
  }
  .homecn-text136 {
    font-size: 16px;
  }
  .homecn-text149 {
    font-size: 30px;
  }
  .homecn-text150 {
    font-size: 22px;
  }
  .homecn-text151 {
    font-size: 16px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 400;
  }
  .homecn-text152 {
    font-size: 30px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 800;
  }
  .homecn-text153 {
    font-size: 22px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 600;
  }
  .homecn-text154 {
    font-size: 16px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 400;
  }
  .homecn-text155 {
    font-size: 30px;
  }
  .homecn-text156 {
    font-size: 22px;
  }
  .homecn-text157 {
    font-size: 16px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 400;
  }
  .homecn-text199 {
    font-size: 22px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 400;
  }
  .homecn-text228 {
    font-size: 22px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 400;
  }
}
