.hero81-header26 {
  gap: var(--dl-space-space-twounits);
}
.hero81-max-width {
  align-self: center;
}
.hero81-column {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.hero81-content {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.hero81-text1 {
  font-size: 30px;
  font-family: "League Spartan";
}
.hero81-text2 {
  width: auto;
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-family: "League Spartan";
  font-weight: 400;
  padding-left: 0px;
  padding-right: 0px;
}
.hero81-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.hero81-action2 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  width: 163px;
  height: 47px;
  display: flex;
  border-color: var(--dl-color-theme-secondary1);
  border-width: 0px;
  border-radius: var(--dl-radius-radius-imageradius);
  flex-direction: row;
  text-decoration: none;
  background-color: #075ce2;
}
.hero81-container1 {
  width: 730px;
  height: 524px;
}
.hero81-container2 {
  display: contents;
}
.hero81-text4 {
  display: inline-block;
}
.hero81-text7 {
  display: inline-block;
}
.hero81-text8 {
  display: inline-block;
  font-size: 16px;
}
.hero81root-class-name {
  background-color: var(--dl-color-theme-secondary1);
}
.hero81root-class-name1 {
  background-color: var(--dl-color-theme-secondary1);
}
@media(max-width: 1600px) {
  .hero81-text8 {
    color: #16224a;
    font-size: 40px;
  }
}
@media(max-width: 479px) {
  .hero81-header26 {
    height: 779px;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .hero81-content {
    align-items: flex-start;
  }
  .hero81-text1 {
    text-align: left;
  }
  .hero81-text2 {
    width: auto;
    text-align: left;
  }
  .hero81-actions {
    width: 100%;
    flex-direction: column;
  }
  .hero81-container1 {
    width: 100%;
    height: 359px;
  }
}
