.testimonial8-container1 {
  background-color: #ffffff;
}
.testimonial8-text10 {
  color: rgb(22, 34, 74);
  font-size: 3em;
}
.testimonial8-max-width {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.testimonial8-slider {
  width: 100%;
  height: auto;
  display: inline-block;
}
.testimonial8-slider-slide1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.testimonial8-container2 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-left: 80px;
  flex-direction: row;
  justify-content: center;
}
.testimonial8-content1 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  max-width: 800px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.testimonial8-stars1 {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.testimonial8-icon10 {
  fill: #16224a;
}
.testimonial8-icon12 {
  fill: #16224a;
}
.testimonial8-icon14 {
  fill: #16224a;
}
.testimonial8-icon16 {
  fill: #16224a;
}
.testimonial8-icon18 {
  fill: #16224a;
}
.testimonial8-text11 {
  color: rgb(22, 34, 74);
  font-size: 18px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 400;
}
.testimonial8-avatar1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.testimonial8-avatar-content1 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.testimonial8-text12 {
  color: rgb(22, 34, 74);
  font-size: 18px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 600;
}
.testimonial8-thumbnail1 {
  width: var(--dl-size-size-large);
  height: var(--dl-size-size-large);
  max-width: auto;
}
.testimonial8-slider-slide2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.testimonial8-container3 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.testimonial8-content2 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  max-width: 800px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.testimonial8-stars2 {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.testimonial8-icon20 {
  fill: #16224a;
}
.testimonial8-icon22 {
  fill: #16224a;
}
.testimonial8-icon24 {
  fill: #16224a;
}
.testimonial8-icon26 {
  fill: #16224a;
}
.testimonial8-icon28 {
  fill: #16224a;
}
.testimonial8-text13 {
  color: rgb(22, 34, 74);
  font-size: 18px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 400;
}
.testimonial8-avatar2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
}
.testimonial8-avatar-content2 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.testimonial8-text14 {
  color: rgb(22, 34, 74);
  font-size: 18px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 600;
}
.testimonial8-thumbnail2 {
  width: var(--dl-size-size-large);
  height: var(--dl-size-size-large);
  max-width: auto;
}
.testimonial8-slider-slide3 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.testimonial8-container4 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.testimonial8-content3 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  max-width: 800px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.testimonial8-stars3 {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.testimonial8-icon30 {
  fill: #16224a;
}
.testimonial8-icon32 {
  fill: #16224a;
}
.testimonial8-icon34 {
  fill: #16224a;
}
.testimonial8-icon36 {
  fill: #16224a;
}
.testimonial8-icon38 {
  fill: #16224a;
}
.testimonial8-text15 {
  color: rgb(22, 34, 74);
  font-size: 18px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 400;
}
.testimonial8-avatar3 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
}
.testimonial8-avatar-content3 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.testimonial8-text16 {
  color: rgb(22, 34, 74);
  font-size: 18px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 600;
}
.testimonial8-thumbnail3 {
  width: var(--dl-size-size-large);
  height: var(--dl-size-size-large);
  max-width: auto;
}
.testimonial8-slider-pagination {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  display: block;
}
.testimonial8-slider-button-prev {
  fill: #16224a;
  color: #16224a;
}
.testimonial8-slider-button-next {
  fill: #16224a;
  color: #16224a;
}
.testimonial8-text17 {
  display: inline-block;
}
.testimonial8-text20 {
  display: inline-block;
}
.testimonial8-text21 {
  display: inline-block;
}
.testimonial8-text22 {
  display: inline-block;
}
.testimonial8-text23 {
  display: inline-block;
}
.testimonial8-text24 {
  display: inline-block;
}
.testimonial8-text25 {
  display: inline-block;
}

@media(max-width: 1600px) {
  .testimonial8-text23 {
    color: rgb(22, 34, 74);
    font-size: 40px;
  }
}
@media(max-width: 991px) {
  .testimonial8-container2 {
    flex-direction: column;
  }
  .testimonial8-content1 {
    align-items: center;
    justify-content: center;
  }
  .testimonial8-avatar1 {
    gap: 0;
    width: 100%;
    justify-content: space-between;
  }
  .testimonial8-thumbnail1 {
    width: 100%;
  }
  .testimonial8-container3 {
    flex-direction: column;
  }
  .testimonial8-content2 {
    align-items: center;
    justify-content: center;
  }
  .testimonial8-avatar2 {
    gap: 0;
    width: 100%;
    justify-content: space-between;
  }
  .testimonial8-thumbnail2 {
    width: 100%;
  }
  .testimonial8-container4 {
    flex-direction: column;
  }
  .testimonial8-content3 {
    align-items: center;
    justify-content: center;
  }
  .testimonial8-avatar3 {
    gap: 0;
    width: 100%;
    justify-content: space-between;
  }
  .testimonial8-thumbnail3 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .testimonial8-slider-slide1 {
    padding: var(--dl-space-space-threeunits);
  }
  .testimonial8-slider-slide2 {
    padding: var(--dl-space-space-threeunits);
  }
  .testimonial8-slider-slide3 {
    padding: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 479px) {
  .testimonial8-text10 {
    text-align: left;
  }
  .testimonial8-slider-slide1 {
    padding: var(--dl-space-space-oneandhalfunits);
  }
  .testimonial8-container2 {
    width: 479px;
  }
  .testimonial8-content1 {
    width: 270px;
    align-items: center;
    justify-content: center;
  }
  .testimonial8-text11 {
    color: rgb(22, 34, 74);
    width: auto;
    font-size: 18px;
    font-style: normal;
    text-align: left;
    font-family: League Spartan;
    font-weight: 400;
    margin-right: var(--dl-space-space-oneandhalfunits);
  }
  .testimonial8-avatar1 {
    flex-direction: column;
  }
  .testimonial8-avatar-content1 {
    align-items: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .testimonial8-text12 {
    color: var(--dl-color-theme-neutral-dark);
    font-size: 18px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 600;
  }
  .testimonial8-thumbnail1 {
    width: auto;
    height: auto;
    max-width: auto;
  }
  .testimonial8-slider-slide2 {
    padding: var(--dl-space-space-oneandhalfunits);
  }
  .testimonial8-container3 {
    width: 479px;
  }
  .testimonial8-content2 {
    width: auto;
    height: 274px;
  }
  .testimonial8-text13 {
    width: 270px;
    height: 225px;
    font-size: 18px;
    text-align: left;
    font-family: League Spartan;
  }
  .testimonial8-avatar2 {
    height: 97px;
    flex-direction: column;
  }
  .testimonial8-avatar-content2 {
    align-items: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .testimonial8-text14 {
    color: rgb(22, 34, 74);
    font-size: 18px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 600;
  }
  .testimonial8-thumbnail2 {
    width: 220px;
    height: 201px;
  }
  .testimonial8-slider-slide3 {
    padding: var(--dl-space-space-oneandhalfunits);
  }
  .testimonial8-container4 {
    width: 479px;
  }
  .testimonial8-content3 {
    width: auto;
  }
  .testimonial8-text15 {
    color: rgb(22, 34, 74);
    width: 288px;
    height: 232px;
    font-size: 18px;
    font-style: normal;
    text-align: left;
    font-family: League Spartan;
    font-weight: 400;
  }
  .testimonial8-avatar3 {
    flex-direction: column;
  }
  .testimonial8-avatar-content3 {
    align-items: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .testimonial8-text16 {
    font-size: 22px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 600;
  }
  .testimonial8-thumbnail3 {
    width: 220px;
    height: 201px;
  }
  .testimonial8-slider-button-prev {
    border-radius: var(--dl-radius-radius-radius4);
  }
}
