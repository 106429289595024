.hero16-max-width {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.hero16-column {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 1007px;
  height: 805px;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  animation-name: fadeInLeft;
  flex-direction: column;
  animation-delay: 0s;
  justify-content: center;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.hero16-text10 {
  color: var(--dl-color-theme-primary2);
  font-size: 60px;
  font-family: "League Spartan";
}
.hero16-text11 {
  font-size: 25px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 800;
}
.hero16-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  justify-content: center;
}
.hero16-button1 {
  height: 45px;
  border-color: var(--dl-color-theme-primary2);
  border-radius: var(--dl-radius-radius-imageradius);
  text-decoration: none;
  background-color: var(--dl-color-theme-primary2);
}
.hero16-text12 {
  font-size: 16px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 400;
}
.hero16-button2 {
  fill: var(--dl-color-theme-primary2);
  color: var(--dl-color-theme-primary2);
  width: 152px;
  height: 44px;
  border-color: var(--dl-color-theme-primary2);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-imageradius);
  text-decoration: none;
}
.hero16-text13 {
  font-size: 16px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 400;
}
.hero16-content {
  gap: var(--dl-space-space-unit);
  width: 50%;
  height: 900px;
  display: flex;
}
.hero16-column-container1 {
  width: 50%;
}
.hero16-placeholder-image10 {
  width: 100%;
  height: 400px;
}
.hero16-placeholder-image11 {
  width: 100%;
  height: 400px;
}
.hero16-placeholder-image12 {
  width: 100%;
  height: 400px;
}
.hero16-placeholder-image13 {
  width: 100%;
  height: 400px;
}
.hero16-placeholder-image14 {
  width: 100%;
  height: 400px;
}
.hero16-placeholder-image15 {
  width: 100%;
  height: 400px;
}
.hero16-column-container2 {
  width: 50%;
}
.hero16-placeholder-image16 {
  width: 100%;
  height: 400px;
}
.hero16-placeholder-image17 {
  width: 100%;
  height: 400px;
}
.hero16-placeholder-image18 {
  width: 100%;
  height: 400px;
}
.hero16-placeholder-image19 {
  width: 100%;
  height: 400px;
}
.hero16-placeholder-image20 {
  width: 100%;
  height: 400px;
}
.hero16-placeholder-image21 {
  width: 100%;
  height: 400px;
}
.hero16-container2 {
  display: contents;
}
.hero16-text14 {
  display: inline-block;
}
.hero16-text15 {
  display: inline-block;
}
.hero16-text18 {
  display: inline-block;
}
.hero16-text19 {
  display: inline-block;
}
.hero16root-class-name {
  display: none;
}
.hero16root-class-name1 {
  display: none;
}
@media(max-width: 1600px) {
  .hero16-column {
    align-self: flex-start;
    justify-content: flex-start;
  }
  .hero16-text10 {
    color: var(--dl-color-theme-primary2);
    height: 164px;
  }
  .hero16-text11 {
    color: var(--dl-color-theme-neutral-dark);
    font-size: 26px;
    font-style: normal;
    font-weight: 800;
  }
  .hero16-button2 {
    width: 144px;
    height: 43px;
  }
  .hero16-text16 {
    height: 164px;
    font-size: 58px;
    font-family: Inter;
  }
}
@media(max-width: 991px) {
  .hero16-max-width {
    flex-direction: column;
  }
  .hero16-column {
    width: 100%;
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
  }
  .hero16-content {
    width: 100%;
    height: 100vh;
    justify-content: center;
  }
}
@media(max-width: 767px) {
  .hero16-column {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .hero16-text10 {
    color: var(--dl-color-theme-primary2);
    text-align: center;
  }
  .hero16-text11 {
    text-align: center;
  }
  .hero16-actions {
    width: 100%;
    justify-content: center;
  }
  .hero16-text16 {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .hero16-column {
    height: 584px;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    justify-content: center;
  }
  .hero16-text10 {
    color: var(--dl-color-theme-primary2);
    height: auto;
    font-size: 45px;
    text-align: center;
    font-family: League Spartan;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .hero16-text11 {
    color: var(--dl-color-theme-neutral-dark);
    font-size: 25px;
    font-style: normal;
    text-align: center;
    font-family: League Spartan;
    font-weight: 800;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .hero16-actions {
    flex-direction: column;
  }
  .hero16-button1 {
    width: 100%;
  }
  .hero16-button2 {
    width: 100%;
  }
  .hero16-content {
    height: 831px;
  }
  .hero16-text16 {
    text-align: center;
  }
}
