.hero82-header26 {
  gap: var(--dl-space-space-twounits);
}
.hero82-max-width {
  align-self: center;
}
.hero82-column {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.hero82-content {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.hero82-text1 {
  color: #16224a;
  font-size: 50px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 700;
}
.hero82-text2 {
  color: #16224a;
  font-size: 30px;
  font-family: "League Spartan";
  font-weight: 400;
}
.hero82-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.hero82-action2 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  width: 163px;
  height: 47px;
  display: flex;
  border-color: var(--dl-color-theme-secondary1);
  border-width: 0px;
  border-radius: var(--dl-radius-radius-imageradius);
  flex-direction: row;
  text-decoration: none;
  background-color: #075ce2;
}
.hero82-text3 {
  color: var(--dl-color-theme-secondary1);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.hero82-action1 {
  fill: var(--dl-color-theme-primary2);
  color: #075ce2;
  width: 157px;
  display: flex;
  font-size: 20px;
  border-color: #075ce2;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-imageradius);
  flex-direction: row;
  text-decoration: none;
  background-color: var(--dl-color-theme-secondary1);
}
.hero82-text4 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.hero82-text5 {
  display: inline-block;
}
.hero82-text6 {
  display: inline-block;
}
.hero82-text7 {
  display: inline-block;
}
.hero82-text8 {
  display: inline-block;
}
.hero82root-class-name {
  background-color: var(--dl-color-theme-secondary1);
}
.hero82root-class-name1 {
  background-color: var(--dl-color-theme-secondary1);
}
@media(max-width: 479px) {
  .hero82-actions {
    width: 100%;
    flex-direction: column;
  }
  .hero82-text5 {
    font-size: 40px;
  }
  .hero82-text7 {
    font-size: 22px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 600;
  }
}
