.navbar12-container1 {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
}
.navbar12-navbar-interactive {
  width: 100%;
  height: 102px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: var(--dl-color-theme-secondary1);
}
.navbar12-container2 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.navbar12-link7 {
  display: contents;
}
.navbar12-container3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  text-decoration: none;
}
.navbar12-image {
  width: 200px;
  object-fit: cover;
}
.navbar12-desktop-menu {
  flex: 1;
  display: flex;
  position: relative;
  justify-content: space-between;
}
.navbar12-links1 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.navbar12-link11 {
  color: rgb(22, 34, 74);
  font-size: 18px;
  font-family: "League Spartan";
}
.navbar12-link21 {
  color: rgb(22, 34, 74);
  font-size: 18px;
  font-family: "League Spartan";
  text-decoration: none;
}
.navbar12-link31 {
  color: rgb(22, 34, 74);
  font-size: 18px;
  font-family: "League Spartan";
  text-decoration: none;
}
.navbar12-link32 {
  color: rgb(22, 34, 74);
  font-size: 18px;
  font-family: "League Spartan";
  text-decoration: none;
}
.navbar12-link41 {
  color: rgb(22, 34, 74);
  font-size: 18px;
  font-family: "League Spartan";
  text-decoration: none;
}
.navbar12-buttons1 {
  gap: var(--dl-space-space-twounits);
  top: 0px;
  right: 34px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navbar12-link8 {
  display: contents;
}
.navbar12-icon10 {
  text-decoration: none;
}
.navbar12-action21 {
  fill: #075ce2;
  color: #075ce2;
  width: 127px;
  height: 39px;
  display: flex;
  border-color: #075ce2;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-imageradius);
  flex-direction: row;
  text-decoration: none;
}
.navbar12-text10 {
  color: #075ce2;
  font-size: 18px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 700;
}
.navbar12-action22 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  width: 127px;
  height: 39px;
  display: flex;
  border-color: var(--dl-color-theme-secondary1);
  border-width: 0px;
  border-radius: var(--dl-radius-radius-imageradius);
  flex-direction: row;
  text-decoration: none;
  background-color: #075ce2;
}
.navbar12-text11 {
  color: var(--dl-color-theme-secondary1);
  font-size: 18px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 700;
}
.navbar12-burger-menu {
  display: none;
}
.navbar12-icon12 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar12-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-theme-neutral-light);
}
.navbar12-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar12-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.navbar12-navlink {
  display: contents;
}
.navbar12-logo {
  height: 3rem;
  text-decoration: none;
}
.navbar12-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar12-icon14 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar12-links2 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.navbar12-link12 {
  color: rgb(22, 34, 74);
  font-size: 18px;
  font-family: "League Spartan";
}
.navbar12-link22 {
  color: rgb(22, 34, 74);
  font-size: 18px;
  font-family: "League Spartan";
  text-decoration: none;
}
.navbar12-link33 {
  color: rgb(22, 34, 74);
  font-size: 18px;
  font-family: "League Spartan";
  text-decoration: none;
}
.navbar12-link34 {
  color: rgb(22, 34, 74);
  font-size: 18px;
  font-family: "League Spartan";
  text-decoration: none;
}
.navbar12-link42 {
  color: rgb(22, 34, 74);
  font-size: 18px;
  font-family: "League Spartan";
  text-decoration: none;
}
.navbar12-link5 {
  color: rgb(22, 34, 74);
  font-size: 18px;
  font-family: "League Spartan";
  text-decoration: none;
}
.navbar12-link6 {
  color: rgb(22, 34, 74);
  font-size: 18px;
  font-family: "League Spartan";
  text-decoration: none;
}
.navbar12-buttons2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
}
.navbar12-action23 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  width: 127px;
  height: 39px;
  display: flex;
  border-color: var(--dl-color-theme-secondary1);
  border-width: 0px;
  border-radius: var(--dl-radius-radius-imageradius);
  flex-direction: row;
  text-decoration: none;
  background-color: #075ce2;
}
.navbar12-text12 {
  color: var(--dl-color-theme-secondary1);
  font-size: 18px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 700;
}
.navbar12-action24 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  width: 127px;
  height: 39px;
  display: flex;
  border-color: var(--dl-color-theme-secondary1);
  border-width: 0px;
  border-radius: var(--dl-radius-radius-imageradius);
  flex-direction: row;
  text-decoration: none;
  background-color: #075ce2;
}
.navbar12-text13 {
  color: var(--dl-color-theme-secondary1);
  font-size: 18px;
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 700;
}
.navbar12-link9 {
  display: contents;
}
.navbar12-icon16 {
  text-decoration: none;
}
.navbar12-icon-group {
  gap: var(--dl-space-space-twounits);
  display: flex;
}
.navbar12-text14 {
  display: inline-block;
}
.navbar12-text15 {
  display: inline-block;
}
.navbar12-text16 {
  display: inline-block;
}
.navbar12-text17 {
  display: inline-block;
}
.navbar12-text18 {
  display: inline-block;
}
.navbar12-text19 {
  display: inline-block;
}
.navbar12-text20 {
  display: inline-block;
}
.navbar12-text21 {
  display: inline-block;
}
.navbar12-text22 {
  display: inline-block;
}
.navbar12-text23 {
  display: inline-block;
}
.navbar12-text24 {
  display: inline-block;
}
.navbar12root-class-name {
  background-color: var(--dl-color-theme-secondary1);
}
.navbar12root-class-name1 {
  background-color: var(--dl-color-theme-secondary1);
}
.navbar12root-class-name2 {
  background-color: var(--dl-color-theme-secondary1);
}
.navbar12root-class-name3 {
  background-color: var(--dl-color-theme-secondary1);
}
.navbar12root-class-name4 {
  display: none;
  background-color: var(--dl-color-theme-secondary1);
}
@media(max-width: 1600px) {
  .navbar12-links1 {
    justify-content: flex-start;
  }
  .navbar12-link21 {
    color: var(--dl-color-theme-neutral-dark);
  }
  .navbar12-link41 {
    color: rgb(22, 34, 74);
    font-size: 18px;
    font-family: League Spartan;
    text-decoration: none;
  }
  .navbar12-text10 {
    fill: #075ce2;
    color: #075ce2;
    font-size: 18px;
  }
  .navbar12-text11 {
    color: var(--dl-color-theme-secondary1);
    font-size: 18px;
  }
  .navbar12-link22 {
    color: var(--dl-color-theme-neutral-dark);
  }
  .navbar12-link42 {
    font-size: 18px;
    text-decoration: none;
  }
  .navbar12-link5 {
    font-size: 18px;
    text-decoration: none;
  }
  .navbar12-link6 {
    font-size: 18px;
    text-decoration: none;
  }
  .navbar12-text12 {
    color: var(--dl-color-theme-secondary1);
    font-size: 18px;
  }
  .navbar12-text13 {
    color: var(--dl-color-theme-secondary1);
    font-size: 18px;
  }
}
@media(max-width: 991px) {
  .navbar12-link11 {
    color: var(--dl-color-theme-neutral-dark);
    font-size: 16px;
  }
  .navbar12-link21 {
    color: var(--dl-color-theme-neutral-dark);
    font-size: 16px;
    text-decoration: none;
  }
  .navbar12-link31 {
    font-size: 16px;
    text-decoration: none;
  }
  .navbar12-link32 {
    font-size: 16px;
    text-decoration: none;
  }
  .navbar12-link41 {
    font-size: 18px;
    text-decoration: none;
  }
  .navbar12-text10 {
    color: var(--dl-color-theme-secondary1);
    font-size: 18px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 700;
  }
  .navbar12-text11 {
    color: var(--dl-color-theme-secondary1);
    font-size: 18px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 700;
  }
  .navbar12-link12 {
    color: var(--dl-color-theme-neutral-dark);
    font-size: 16px;
  }
  .navbar12-link22 {
    color: var(--dl-color-theme-neutral-dark);
    font-size: 16px;
    text-decoration: none;
  }
  .navbar12-link33 {
    font-size: 16px;
    text-decoration: none;
  }
  .navbar12-link34 {
    font-size: 16px;
    text-decoration: none;
  }
  .navbar12-link42 {
    font-size: 18px;
    text-decoration: none;
  }
  .navbar12-link5 {
    font-size: 18px;
    text-decoration: none;
  }
  .navbar12-link6 {
    font-size: 18px;
    text-decoration: none;
  }
  .navbar12-text12 {
    color: var(--dl-color-theme-secondary1);
    font-size: 18px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 700;
  }
  .navbar12-text13 {
    color: var(--dl-color-theme-secondary1);
    font-size: 18px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 700;
  }
}
@media(max-width: 767px) {
  .navbar12-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .navbar12-burger-menu {
    align-items: center;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .navbar12-navbar-interactive {
    height: 123px;
    padding: var(--dl-space-space-unit);
    align-items: center;
    padding-right: 16px;
    justify-content: space-between;
  }
  .navbar12-image {
    width: var(--dl-size-size-large);
    height: var(--dl-size-size-large);
  }
  .navbar12-desktop-menu {
    display: none;
  }
  .navbar12-link11 {
    color: var(--dl-color-theme-neutral-dark);
    font-size: 16px;
    font-family: League Spartan;
  }
  .navbar12-link21 {
    color: var(--dl-color-theme-neutral-dark);
    font-size: 16px;
    font-family: League Spartan;
  }
  .navbar12-link31 {
    fill: var(--dl-color-theme-neutral-dark);
    color: var(--dl-color-theme-neutral-dark);
    font-size: 16px;
    font-family: League Spartan;
    text-decoration: none;
  }
  .navbar12-link32 {
    fill: var(--dl-color-theme-neutral-dark);
    color: var(--dl-color-theme-neutral-dark);
    font-size: 16px;
    font-family: League Spartan;
    text-decoration: none;
  }
  .navbar12-link41 {
    color: rgb(22, 34, 74);
    font-size: 16px;
    font-family: League Spartan;
    text-decoration: none;
  }
  .navbar12-text10 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    font-size: 18px;
    border-color: var(--dl-color-theme-secondary1);
    border-style: hidden;
    border-width: 1px;
  }
  .navbar12-text11 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    font-size: 18px;
    border-color: var(--dl-color-theme-secondary1);
    border-style: hidden;
    border-width: 1px;
  }
  .navbar12-burger-menu {
    display: flex;
  }
  .navbar12-icon12 {
    width: var(--dl-size-size-small);
    height: var(--dl-size-size-small);
    align-self: center;
  }
  .navbar12-mobile-menu {
    padding: var(--dl-space-space-unit);
  }
  .navbar12-nav {
    height: 447px;
    border-radius: var(--dl-radius-radius-radius4);
  }
  .navbar12-top {
    height: 77px;
  }
  .navbar12-logo {
    width: var(--dl-size-size-large);
    height: var(--dl-size-size-large);
  }
  .navbar12-link12 {
    color: var(--dl-color-theme-neutral-dark);
    font-size: 16px;
    font-family: League Spartan;
  }
  .navbar12-link22 {
    color: var(--dl-color-theme-neutral-dark);
    font-size: 16px;
    font-family: League Spartan;
  }
  .navbar12-link33 {
    fill: var(--dl-color-theme-neutral-dark);
    color: var(--dl-color-theme-neutral-dark);
    font-size: 16px;
    font-family: League Spartan;
    text-decoration: none;
  }
  .navbar12-link34 {
    fill: var(--dl-color-theme-neutral-dark);
    color: var(--dl-color-theme-neutral-dark);
    font-size: 16px;
    font-family: League Spartan;
    text-decoration: none;
  }
  .navbar12-link42 {
    color: rgb(22, 34, 74);
    font-size: 16px;
    font-family: League Spartan;
    text-decoration: none;
  }
  .navbar12-link5 {
    color: rgb(22, 34, 74);
    font-size: 16px;
    font-family: League Spartan;
    text-decoration: none;
  }
  .navbar12-link6 {
    color: rgb(22, 34, 74);
    font-size: 16px;
    font-family: League Spartan;
    text-decoration: none;
  }
  .navbar12-action23 {
    fill: #075ce2;
    color: #075ce2;
    border-color: #075ce2;
    border-width: 1px;
    background-color: transparent;
  }
  .navbar12-text12 {
    fill: var(--dl-color-theme-secondary1);
    color: #075ce2;
    font-size: 18px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 700;
    border-color: var(--dl-color-theme-secondary1);
    border-style: hidden;
    border-width: 1px;
  }
  .navbar12-text13 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    font-size: 18px;
    border-color: var(--dl-color-theme-secondary1);
    border-style: hidden;
    border-width: 1px;
  }
}
