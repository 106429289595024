.features4-layout301 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.features4-max-width {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.features4-text10 {
  font-size: 40px;
  font-family: "League Spartan";
}
.features4-row {
  width: 100%;
  align-self: flex-start;
}
.features4-feature1 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: auto;
  display: flex;
  overflow: hidden;
  max-width: 600px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.features4-content1 {
  align-self: stretch;
  align-items: center;
}
.features4-section-title1 {
  align-self: flex-start;
  align-items: flex-start;
}
.features4-icon10 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  align-self: flex-start;
}
.features4-title1 {
  font-family: "League Spartan";
}
.features4-description1 {
  text-align: left;
}
.features4-feature2 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: auto;
  display: flex;
  overflow: hidden;
  max-width: 600px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.features4-icon14 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  align-self: flex-start;
}
.features4-content2 {
  align-self: stretch;
  align-items: center;
}
.features4-section-title2 {
  align-self: flex-start;
  align-items: flex-start;
}
.features4-title2 {
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 700;
}
.features4-description2 {
  text-align: left;
}
.features4-feature3 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: auto;
  display: flex;
  overflow: hidden;
  max-width: 600px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.features4-icon16 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
}
.features4-content3 {
  align-self: stretch;
  align-items: center;
}
.features4-section-title3 {
  align-self: flex-start;
  align-items: flex-start;
}
.features4-title3 {
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 700;
}
.features4-description3 {
  text-align: left;
}
.features4-feature4 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: auto;
  display: flex;
  overflow: hidden;
  max-width: 600px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.features4-icon18 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  align-self: flex-start;
}
.features4-content4 {
  align-self: stretch;
  align-items: center;
}
.features4-section-title4 {
  align-self: flex-start;
  align-items: flex-start;
}
.features4-title4 {
  font-style: normal;
  font-family: "League Spartan";
  font-weight: 700;
}
.features4-description4 {
  text-align: left;
}
.features4-text11 {
  color: #ffffff;
  display: inline-block;
}
.features4-text12 {
  color: #ffffff;
  display: inline-block;
}
.features4-text13 {
  display: inline-block;
}
.features4-text14 {
  display: inline-block;
}
.features4-text15 {
  display: inline-block;
}
.features4-text16 {
  display: inline-block;
}
.features4-text17 {
  color: #ffffff;
  display: inline-block;
}
.features4-text18 {
  color: #ffffff;
  display: inline-block;
}
.features4-text19 {
  color: rgb(255, 255, 255);
  display: inline-block;
}
.features4root-class-name {
  background-color: #16224a;
}
.features4root-class-name1 {
  background-color: #16224a;
}
.features4root-class-name2 {
  display: none;
  background-color: #16224a;
}
@media(max-width: 1600px) {
  .features4-text10 {
    font-size: 40px;
    font-family: League Spartan;
  }
  .features4-title1 {
    font-size: 30px;
  }
  .features4-description1 {
    font-size: 22px;
  }
  .features4-title2 {
    font-size: 30px;
    font-family: League Spartan;
  }
  .features4-description2 {
    font-size: 22px;
  }
  .features4-title3 {
    font-size: 30px;
    font-family: League Spartan;
  }
  .features4-description3 {
    font-size: 22px;
  }
  .features4-title4 {
    font-size: 30px;
    font-family: League Spartan;
  }
  .features4-description4 {
    font-size: 22px;
  }
}
@media(max-width: 991px) {
  .features4-row {
    width: auto;
    align-items: center;
    flex-direction: column;
  }
  .features4-feature1 {
    width: 100%;
  }
  .features4-feature2 {
    width: 100%;
  }
  .features4-feature3 {
    width: 100%;
  }
  .features4-feature4 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .features4-text10 {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .features4-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .features4-text10 {
    text-align: left;
  }
  .features4-text13 {
    font-size: 16px;
  }
  .features4-text14 {
    font-size: 16px;
  }
  .features4-text15 {
    font-size: 16px;
    font-style: normal;
    font-family: League Spartan;
    font-weight: 400;
  }
  .features4-text16 {
    font-size: 16px;
  }
}
