.terms-of-service-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.terms-of-service-text100 {
  display: inline-block;
}
.terms-of-service-text101 {
  display: inline-block;
}
.terms-of-service-text102 {
  display: inline-block;
}
.terms-of-service-text103 {
  display: inline-block;
}
.terms-of-service-text104 {
  display: inline-block;
}
.terms-of-service-text105 {
  display: inline-block;
}
.terms-of-service-text106 {
  display: inline-block;
}
.terms-of-service-text107 {
  display: inline-block;
}
.terms-of-service-text108 {
  display: inline-block;
}
.terms-of-service-text109 {
  display: inline-block;
}
.terms-of-service-text112 {
  display: inline-block;
}
.terms-of-service-text119 {
  font-weight: 700;
}
.terms-of-service-text124 {
  font-weight: 700;
}
.terms-of-service-text129 {
  font-weight: 700;
}
.terms-of-service-text134 {
  font-weight: 700;
}
.terms-of-service-text161 {
  font-weight: 700;
}
.terms-of-service-text172 {
  font-weight: 700;
}
.terms-of-service-text180 {
  font-weight: 700;
}
.terms-of-service-text185 {
  font-weight: 700;
}
.terms-of-service-text190 {
  font-weight: 700;
}
.terms-of-service-text198 {
  font-weight: 700;
}
.terms-of-service-text204 {
  font-weight: 700;
}
.terms-of-service-text227 {
  display: inline-block;
}
.terms-of-service-text228 {
  display: inline-block;
}
.terms-of-service-text229 {
  display: inline-block;
}
.terms-of-service-text232 {
  display: inline-block;
}
.terms-of-service-text233 {
  display: inline-block;
}
.terms-of-service-text238 {
  display: inline-block;
}
.terms-of-service-text245 {
  display: inline-block;
}
@media(max-width: 479px) {
  .terms-of-service-text114 {
    font-size: 22px;
  }
  .terms-of-service-text117 {
    font-size: 22px;
  }
  .terms-of-service-text119 {
    font-weight: 700;
  }
  .terms-of-service-text122 {
    font-size: 22px;
  }
  .terms-of-service-text124 {
    font-weight: 700;
  }
  .terms-of-service-text127 {
    font-size: 22px;
  }
  .terms-of-service-text129 {
    font-weight: 700;
  }
  .terms-of-service-text132 {
    font-size: 22px;
  }
  .terms-of-service-text134 {
    font-weight: 700;
  }
  .terms-of-service-text137 {
    font-size: 22px;
  }
  .terms-of-service-text140 {
    font-size: 22px;
  }
  .terms-of-service-text145 {
    font-size: 22px;
  }
  .terms-of-service-text147 {
    font-size: 22px;
  }
  .terms-of-service-text149 {
    font-size: 22px;
  }
  .terms-of-service-text151 {
    font-size: 22px;
  }
  .terms-of-service-text153 {
    font-size: 22px;
  }
  .terms-of-service-text155 {
    font-size: 22px;
  }
  .terms-of-service-text157 {
    font-size: 22px;
  }
  .terms-of-service-text159 {
    font-size: 22px;
  }
  .terms-of-service-text161 {
    font-weight: 700;
  }
  .terms-of-service-text164 {
    font-size: 22px;
  }
  .terms-of-service-text167 {
    font-size: 22px;
  }
  .terms-of-service-text170 {
    font-size: 22px;
  }
  .terms-of-service-text172 {
    font-weight: 700;
  }
  .terms-of-service-text175 {
    font-size: 22px;
  }
  .terms-of-service-text180 {
    font-weight: 700;
  }
  .terms-of-service-text183 {
    font-size: 22px;
  }
  .terms-of-service-text185 {
    font-weight: 700;
  }
  .terms-of-service-text188 {
    font-size: 22px;
  }
  .terms-of-service-text190 {
    font-weight: 700;
  }
  .terms-of-service-text193 {
    font-size: 22px;
  }
  .terms-of-service-text196 {
    font-size: 22px;
  }
  .terms-of-service-text198 {
    font-weight: 700;
  }
  .terms-of-service-text202 {
    font-size: 22px;
  }
  .terms-of-service-text204 {
    font-weight: 700;
  }
  .terms-of-service-text207 {
    font-size: 22px;
  }
  .terms-of-service-text211 {
    font-size: 22px;
  }
  .terms-of-service-text213 {
    font-weight: 700;
  }
  .terms-of-service-text216 {
    font-size: 22px;
  }
  .terms-of-service-text219 {
    font-size: 22px;
  }
  .terms-of-service-text222 {
    font-size: 22px;
  }
  .terms-of-service-text224 {
    font-weight: 700;
  }
}
