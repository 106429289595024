.footer31-footer4 {
  gap: 80px;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  overflow: hidden;
  position: relative;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.footer31-max-width {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.footer31-content {
  gap: 32px;
  display: flex;
  align-self: center;
  align-items: center;
  flex-shrink: 0;
  justify-content: flex-start;
}
.footer31-container {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer31-logo {
  gap: 24px;
  width: auto;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.footer31-text10 {
  font-size: 20px;
  font-weight: 700;
}
.footer31-links {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
}
.footer31-link42 {
  text-decoration: none;
}
.footer31-link43 {
  text-decoration: none;
}
.footer31-social-links {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
}
.footer31-link3 {
  display: contents;
}
.footer31-icon1 {
  text-decoration: none;
}
.footer31-link5 {
  display: contents;
}
.footer31-icon3 {
  text-decoration: none;
}
.footer31-link6 {
  display: contents;
}
.footer31-icon5 {
  text-decoration: none;
}
.footer31-link7 {
  display: contents;
}
.footer31-icon7 {
  text-decoration: none;
}
.footer31-text15 {
  display: inline-block;
}
.footer31-text19 {
  display: inline-block;
}
.footer31-text20 {
  display: inline-block;
}
.footer31-text25 {
  display: inline-block;
}
.footer31-text26 {
  display: inline-block;
}
@media(max-width: 991px) {
  .footer31-logo {
    width: auto;
  }
}
@media(max-width: 767px) {
  .footer31-content {
    flex-direction: column;
  }
  .footer31-link11 {
    text-align: center;
  }
  .footer31-link12 {
    text-align: center;
  }
  .footer31-link2 {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .footer31-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .footer31-content {
    width: 100%;
  }
  .footer31-link11 {
    text-align: left;
  }
  .footer31-links {
    width: auto;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .footer31-link12 {
    text-align: left;
  }
  .footer31-link2 {
    text-align: left;
  }
}
